/* eslint-disable max-len */
/* eslint-disable no-console */
import { MDXProvider } from "@mdx-js/react";
import cx from "classnames";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { CallUs, SEO, SiteLayout, TasFollowButtons, TasReactButtons, TasShareButtons } from "../components";
import { ServiceCard } from "../components/servicecard";
/* eslint-disable */
import { getServRefElement, mdxcomponents, PhotoGallary1 } from "./common";

/**
 * Interface for the Pricing Page props.
 */
//  type IPricingPageProps = TestableComponentInterface;

/**
  * Pricing page of the site.
  *
  * @param {PageProps<IPricingPageProps>} props - Props injected to the component.
  * @return {React.ReactElement}
  */
const ContentPageTemplate = (props: any): ReactElement => {

  const {
    data,
    pageContext
  } = props;

  const {
    service,
    site,
    refprods,
    refservice
  } = data;

  const { frontmatter } = service;
  const refsrvs = (refservice ? refservice.edges : null);
  const refprodslist = (refprods ? refprods.edges : null);

  const pagestyles = cx(`text-xl tracking-wider leading-relaxed mt-6 font-light ${frontmatter.pagestyles}`);

  const pageimage = frontmatter.heroimage ?  frontmatter.heroimage.file.childImageSharp.gatsbyImageData.images.fallback.src : null;

  var priceinfo  = null;
  let costs;
  if(service.frontmatter.pricerange){
    costs = service.frontmatter.pricerange.split("-");
    priceinfo = (costs.length > 1) ? `₹ ${costs[0]} to  ₹ ${costs[1]}` : `Starting from ₹ ${costs[0]}/-`;
  }

  const { originalPath } = pageContext;
  const pageurl = site.siteMetadata.siteUrl + originalPath;

  const mdxcomps = { ...mdxcomponents, ...getServRefElement(props) };

  const jsondata = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": pageContext.pagetitle,
    "image": site.siteMetadata.siteUrl + "/" + pageimage,
    "description": frontmatter.description,
    "brand": {
      "@type": "Brand",
      "name": "TAS"
    },
    "offers": {
      "@type": "Offer",
      "url": pageurl,
      "priceCurrency": "INR",
      "price": costs == null || costs.length == 0 ? 0 : costs[0],
      "availability": "https://schema.org/InStock",
      "itemCondition": "https://schema.org/NewCondition"
    }
  };
  

  //
  // Related products list
  // 
  const RelatedProducts =  (refprodslist && refprodslist.length > 0) ?
    <div className="flex  flex-col flex-grow w-full  flex-wrap place-items-start justify-center gap-2 p-4">
      <p className="flex flex-row flex-grow ">
        <span className="underline text-2xl">Related Products</span>
      </p>
      <div className="flex flex-wrap flex-row flex-grow   items-stretch  place-items-center gap-4 mt-6">
        {refprodslist.map((obj: any, index: number) => (
          <ServiceCard data-testid={index} key={index} data={obj.node}  siteroot={ site.siteMetadata.siteUrl }/>
        ))}
      </div>
    </div> : null;

          // 
          // Related Services list
          // 
  const RelatedServices = (refsrvs && refsrvs.length > 0) ?
    <div className="flex  flex-col flex-grow w-full  flex-wrap place-items-start justify-center gap-2 p-4">
       <p className="flex flex-row flex-grow ">
         <span className="underline text-2xl">Related Services</span>
       </p>
       <div className="flex flex-wrap flex-row flex-grow  items-stretch place-items-center gap-4 mt-6">
         {refsrvs.map((obj: any, index: number) => (
           <ServiceCard data-testid={index} key={index} data={obj.node}  siteroot={ site.siteMetadata.siteUrl }/>
         ))}
       </div>
     </div>
     : null;
  
  const relatedLists = frontmatter.model == "product" ? [RelatedProducts, RelatedServices] : [RelatedServices, RelatedProducts];

  return (
    <SiteLayout data-testid={`service-${frontmatter.uid}-site-layout`} className="text-gray-400 ">
      <Helmet>

        <meta name="title" content={"TAS (Travancore Auto Spa) - Service : " + pageContext.pagetitle} />
        <meta name="description" content={frontmatter.description + " " + frontmatter.hashkeywords} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={site.siteMetadata.siteUrl + "/" + service.fields.slug} />
        <meta property="og:title" content={"TAS (Travancore Auto Spa) - Service : " + pageContext.pagetitle} />
        <meta property="og:description" content={frontmatter.description + " " + frontmatter.hashkeywords} />
        <meta property="og:image" content={site.siteMetadata.siteUrl + "/" + pageimage} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={site.siteMetadata.siteUrl + "/" + service.fields.slug} />
        <meta property="twitter:title" content={"TAS (Travancore Auto Spa) - Service : " + pageContext.pagetitle} />
        <meta property="twitter:description" content={frontmatter.description + " " + frontmatter.hashkeywords} />
        <meta property="twitter:image" content={site.siteMetadata.siteUrl + "/" + frontmatter.image} />

        {pageContext.pagecopies.map((path: string, index: number) => (
          <link rel="canonical" key={index} href={`${site.siteMetadata.siteUrl}/${path}`} />
        ))}

        <script type="application/ld+json">
          {JSON.stringify(jsondata)}
        </script>

      </Helmet>
      <SEO title={pageContext.pagetitle} />
      <TasShareButtons pageContext={ pageContext }/>  
     
      <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
        <div className="group">
        <div className="flex flex-row grow flex-wrap place-items-center justify-center">
          <div className="grow content-end m-4  justify-center  place-items-center  text-center">
            <h1 className="group-hover:scale-125 heading text-4xl md:text-6xl font-light font-sans md:leading-tight dark:text-amber-400  transition-all duration-500 ease-in-out transform">
              {frontmatter.title}
            </h1>
            <h2 className="text-xl text-gray-400 mt-2 text-center">
              {frontmatter.description}
            </h2>
          </div>
        </div>
        { frontmatter.heroimage &&
          <div className="grow content-end justify-center  place-items-center pb-10">
            <div className="relative max-w-4xl mx-auto px-4">
              <GatsbyImage
                image={getImage(frontmatter.heroimage.file)}
                alt={frontmatter.heroimage.title}
                className="rounded object-cover w-full group-hover:scale-125 transition-all duration-500 ease-in-out transform" />
            </div>
          </div>
        }
        </div>
      </div>
      <div className="py-4">
        <div className="w-full border-t  border-gray-800">
         
        </div>
      </div>
      <div className="relative flex-grow px-4 dark:text-gray-300">
        <CallUs />
      </div>

      <div className="flex flex-col place-items-center justify-center  pb-6">
        
        <div className="grow md:w-4/5 relative mx-auto px-4 text-gray-300 font-light justify-center  place-items-center m-4">
         
          <div className={pagestyles}>

         
            <MDXProvider components={ mdxcomps }>
              <MDXRenderer frontmatter={service.frontmatter}>{service.body}</MDXRenderer>
            </MDXProvider>
          </div>
          {frontmatter.photos &&
            <div className="flex grow items-stretch">
              <PhotoGallary1 frontmatter={frontmatter} />
            </div>
          }
         
          {/* hashkeywords */}
          {frontmatter.hashkeywords ? (
            <p className="flex flex-wrap flex-grow justify-center place-content-center place-items-center gap-1 mt-6">
              {
                frontmatter.hashkeywords.split(",").map((tag:string, i:number) => <span key={ i } className="tagbadge">{ tag }</span>)
              }
              
            </p>
          ) : (
            ""
          )}
         
         
        <div className="w-full border-t  border-gray-800 mt-6 mb-6"></div>    
        

          { /** Add related products and/or services */}
          { relatedLists }

        </div>
      </div>
      
      <TasFollowButtons/>
      <div  className="flex flex-row  w-full  place-items-center justify-center ">
        <TasReactButtons pageContext={ pageContext }/>  
      </div>
    </SiteLayout>
  );
};


export default ContentPageTemplate;
export const pageQuery = graphql`
query pagequery($id: String, $relatedsrvs: [String], $relatedprods: [String], $refuids: [String]) {
  site: site {
    siteMetadata {
      siteUrl
      title
      serviceurl
      projecturl
      blogurl
      author
    }
  }
  service: mdx(id: {eq: $id}) {
    id
    body
    slug
    frontmatter {
      model
      uid
      title
      subtitle
      heroimage {
        title
        file {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      description
      style
      order
      relatedservices
      relatedprods
      tags
      alternatives
      serviceinterval
      hashkeywords
      thumbgallery
      pagestyles
      photosmeta
      photos {
        relativePath
        name
        childImageSharp {
          gatsbyImageData(formats: [WEBP, JPG])
        }
      }
    }
    fields {
      slug
    }
  }
  refservice: allMdx(
    filter: {frontmatter: { uid: {in: $relatedsrvs} }}
  ) {
    edges {
      node {
        id
        slug
        frontmatter {
          model
          title
          description
          heroimage {
            title
            file {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
  refprods: allMdx(filter:{frontmatter:{uid:{in: $relatedprods}}}) {
    edges {
      node {
        id
        slug
        frontmatter {
          model
          title
          description
          heroimage {
            title
            file {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
  refdocs: allMdx(
    filter: {frontmatter: { uid: {in: $refuids} }}
  ) {
    edges {
      node {
        id
        slug
        frontmatter {
          model
          title
          description
          uid
          heroimage {
            title
            file {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}

 `;


